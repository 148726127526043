/* PropertyDescription */

.property-description-card {
  background-color: transparent;
  border: none;
  margin-top: -2rem;
  color: #4A5043;
}

@media(min-width: 768px) {
  .property-description-card {
    margin-top: -1rem;
  }
}

.property-description-card .card-body {
  padding: 2rem;
}

.property-description-card .card-text {
  font-size: 1rem;
  line-height: 1.6;
  color: #4A5043;
}

.vrbo-button {
  background-color: white;
  border-color: #7D70BA;
  color: #7D70BA;
  font-weight: bold;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  transition: background-color 0.3s ease;
}

.vrbo-button:hover,
.vrbo-button:focus {
  background-color: #7D70BA;
  color: white;
}

.vrbo-logo {
  height: 24px;
  margin-right: 0.5rem;
  background: white;
  padding: 2px;
  border-radius: 4px;
}