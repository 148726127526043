body {
  background-color: #f5f5f5;
  font-family: 'Open Sans', sans-serif;
  color: #7D70BA;
}

h1 {
  font-family: 'Montserrat', sans-serif;
  color: #4A5043;
}
